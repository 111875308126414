.slick-prev {
    /* Move prev button into slider bounds */
    left: 0;
}

.slick-next {
    /* Move next button into slider bounds */
    right: 0;
}

.slick-prev, .slick-next {
    height: 80%;
    width: 4rem;
    -webkit-text-stroke: 1px rgba(0,0,0,0.65);
    /* Make sure prev/next buttons are on top of slider */
    z-index: 1;

}

.slick-prev:before {
    /* Uses Font Awesome Unicode icon */
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    content: "\f053";
}

.slick-next:before {
    /* Uses Font Awesome Unicode icon */
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    content: "\f054";
}

.slick-prev:before, .slick-next:before {
    font-size: 2rem;
}
