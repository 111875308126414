#v-cal *, #v-cal :after, #v-cal :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

#v-cal {
    background-color: #fff;
    border-radius: 0.25rem;
    border: 1px solid $primary;
    -webkit-box-shadow: 0 4px 22px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 4px 22px 0 rgba(0, 0, 0, .05);
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
}

#v-cal .vcal-btn {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-appearance: button;
    background: none;
    border: 0;
    color: inherit;
    cursor: pointer;
    font: inherit;
    line-height: normal;
    min-width: 27px;
    outline: none;
    overflow: visible;
    padding: 0;
    text-align: center
}

#v-cal .vcal-btn:active {
    border-radius: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(16, 152, 158, .1);
    box-shadow: 0 0 0 2px rgba(16, 152, 158, .1)
}

#v-cal .vcal-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 19.2px 22.4px;
    padding: 1.2rem 1.4rem;
    background-color: $primary;
    color: #FFFFFF;
}

#v-cal .vcal-header svg {
    fill: #ffffff
}

#v-cal .vcal-header__label {
    font-weight: 700;
    text-align: center;
    width: 100%
}

#v-cal .vcal-week {
    background-color: #e7e9ed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

#v-cal .vcal-week span {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 14.28%;
    flex: 0 0 14.28%;
    font-size: 19.2px;
    font-size: 1.2rem;
    font-weight: 700;
    max-width: 14.28%;
    padding: 19.2px 22.4px;
    padding: 1.2rem 1.4rem;
    text-align: center;
    text-transform: uppercase
}

#v-cal .vcal-body {
    background-color: rgba(231, 233, 237, .3);
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

#v-cal .vcal-body, #v-cal .vcal-date {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

#v-cal .vcal-date {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    border-radius: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 14.28%;
    flex: 0 0 14.28%;
    max-width: 14.28%;
    padding: 19.2px 0;
    padding: 1.2rem 0;
    border-radius: 3rem;
}

#v-cal .vcal-date--active {
    cursor: pointer
}

#v-cal .vcal-date--today {
    background-color: #ffffff;
    color: $dark;
}

#v-cal .vcal-date--selected {
    background-color: $primary;
    color: #ffffff;
}

#v-cal .vcal-date--disabled {
    border-radius: 0;
    cursor: not-allowed;
    opacity: .5
}
