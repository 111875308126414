$navbar-height: 200px; // This will be deducted from elements that need to fill above a page fold


@media (max-width: 425px){
    .ar-100vh {
        padding-top: calc(100vh - 400px)!important;
    }
}


.ar-container {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    background-size: cover;
}

.ar-16x9 {
    padding-top: 56.25%;
}

.ar-100vh {
    padding-top: calc(100vh - $navbar-height);
}

.trbl {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.bg-client-dark-blue {
    background-color: #001B5C;
}
.bg-client-green {
    background-color: #93C238;
}

.text-client-green {
    color: #93C238;
}

.banner-color-overlay {
    // background-color: rgba(142, 173, 198, 0.44); // Exact Code from XD - Looks Wrong
    background-color: rgba(68, 97, 179, 0.44);
}

.banner-heading {
    font-size: 5rem;
}
