// Body
$body-bg: #ffffff;

// Typography
// https://getbootstrap.com/docs/5.0/content/typography/
$font-family-sans-serif: parisine-std-narrow-sombre, sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$link-color: #93C238;

// Colors
// https://getbootstrap.com/docs/5.0/customize/color/

$blue: #001B5C;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #C73A3A;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #93C238;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$primary: #001B5C;
$secondary: #6C757D;
$success: #93C238;
$info: #17A2B8;
$warning: #FFC107;
$danger: #C73A3A;
$light: #F8F9FA;
$dark: #343A40;

// Options
// https://getbootstrap.com/docs/5.0/customize/options/
$enable-rounded: true; // Default: true

$btn-focus-box-shadow: none; // Default: $input-btn-focus-box-shadow;

$border-radius:               .5rem;
$border-radius-sm:            .5rem;
$border-radius-lg:            .5rem;
$border-radius-pill:          50rem;
